*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  scroll-behavior: smooth;
  
}
html{
  scroll-behavior: smooth;
  
}
body{
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #000000;
  scroll-behavior: smooth;
  font-family: "Noto Sans JP", sans-serif;
  
}
.scroll-watcher{
  margin-top: 80px;
  height: 6px;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: rgb(0, 183, 255);
  border-radius: 5px;
  width: 100%;
  scale: 0 1;
  animation: scroll-watcher linear;
  animation-timeline: scroll() ;

}
@keyframes scroll-watcher{
  to {scale: 1 1; }
}
li{
  list-style: none;
}
a{
  text-decoration: none;

}
.main{
  background-color: #000000;
 
}
.navbar {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(45deg, #f5f7f6, #5ca0f2);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  font-family: "Noto Sans JP", sans-serif;
}

@keyframes color {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

nav ul {
  display: flex;
  margin-right: 20px;
}

nav ul li {
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
}

nav ul li a {
  color: #000000;
  font-size: 17px;
  padding: 7px 13px;
  border-radius: 3px;
  text-transform: uppercase;
}

.navbar ul.menu.open {
  display: block;
}

.navbar .button-fest .label {
  font-weight: bold;
}

a.active, a:hover {
  transition: .5s;
}

.menu-logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.fa-bars {
  font-size: 30px;
  color: white;
  display: none;
  cursor: pointer;
}

@media (max-width: 858px) {
  .nabar{
    justify-content: space-between;
  }
  .fa-bars {
    display: block;
    color: #002227;
  }

  nav ul {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: linear-gradient(45deg, #83EAF1, #63A4FF);
    background-size: 300% 300%;
    animation: color 12s ease-in-out infinite;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all .5s;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  nav ul.menu.open {
    left: 0;
  }

  nav ul li {
    display: block;
    margin: 50px 0;
    line-height: 30px;
  }


  
  nav ul li a {
    font-size: 20px;
  }

  a:hover, a.active {
    background: none;
    color: #0082e6;
  }
}


.loading-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
}

.loading-image {
  width: 200px; /* Adjust image size */
  height: 200px; /* Adjust image size */
}

.loading-text {
  margin-top: 20px; /* Adjust spacing between image and text */
  font-weight: bold; /* Make text bold */
  font-size: 1.2em; /* Adjust font size */
  color: white;
}

.logo img {
  width: 90px;
  height: 90px;
}

@keyframes color{
  0%{
    background-position: 0 50%;

  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0 50%;
  }
}

.button-fest{
  display: inline-flex;
  position: relative;
  height: 60px;
  margin-right: 20px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  background: #000000;
  color: #ffffff;
  border-radius: 36px;
  box-shadow: 0px 24px 74px rgb(60 0 189 / 20%);
  font-size: 16px;
  font-weight: 700;
  padding: 0 30px;
  text-decoration: unset;
}
.button-fest:hover{
  background: rgb(255, 255, 255);
  text-decoration: none;
  color: #000000;
 
}
video {
  
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  padding-top: 10px;
  object-fit:fill;
  Overflow-clip-margin: content-box;
  Overflow: clip;
  

 
}
@keyframes fade-out{
  to {opacity: 0;}

}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
 
}
.content h1{
  font-size: 100px;
  text-shadow:0px 10px 20px rgba(0,0,0,.5);
  font-family: "Karla", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  margin-bottom: 0px;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  padding-left: 100px;
  margin-top: 100px;
  
  
}
.content h2{
  font-size: 100px;
  margin-top: 0px;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  padding-left: 100px;
  margin-bottom: 0px;
  
}
.content h3{
  margin-top: 0px;
  font-size: x-large;
  font-family: "Roboto Slab", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  padding-left: 100px;
  font-weight: bolder;
  
}

.about1{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  /* margin-top: 97vh ; */
  /* background-color: #000000; */
  
  
  
}
.about1 h1{
  color: #ffffff;
  padding-top: 50px;
  font-size: 60px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;
  margin-top: 50px;
  overflow: hidden;
  animation: fade-out linear;
  animation-timeline: view() ;
  animation-range: exit -200px;
  
  
}
.wrapper{
  
  margin: 60px auto;
  flex-wrap: wrap;
 
  text-align: center;
  
}
.wrapper1 {
  padding-left: 50px;
  padding-right: 50px;
  margin: 60px auto;
  flex-wrap: wrap;
  text-align: center;
}


@media (max-width: 1024px) {
  .wrapper1 {
    padding-left: 30px;
    padding-right: 30px;
    margin: 50px auto;
  }
}

@media (max-width: 768px) {
  .wrapper1 {
    padding-left: 20px;
    padding-right: 20px;
    margin: 40px auto;
  }
}

@media (max-width: 480px) {
  .wrapper1 {
    padding-left: 10px;
    padding-right: 10px;
    margin: 30px auto;
    text-align: justify;
  }
}
.About{
  /* background: linear-gradient(45deg,#000000,#2C3E50); */
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  margin-top: 100vh;
}
.Aboutt{
  padding-left: 350px;
  padding-right: 350px;
  
  margin: 60px auto;
  flex-wrap: wrap;
  
  text-align: center;
  
}

@media (max-width: 1200px) {
  .Aboutt {
    padding-left: 200px;
    padding-right: 200px;
  }
}

@media (max-width: 992px) {
  .Aboutt {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (max-width: 768px) {
  .Aboutt {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 576px) {
  .Aboutt {
    padding-left: 20px;
    padding-right: 20px;
  }
}


.content1{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding-left: 350px;
  padding-right: 350px;
  float: left;
  justify-content: center;
  text-align: left;
  padding: 0px 30px;
  animation: fade-out linear;
  animation-timeline: view() ;
  animation-range: exit -200px;
  
}
.content1 img{
 
  display: flex;
  justify-content: center;
  align-items: center;

}
.content1 h3{
  font-size: large;
  font-weight: 300;
  letter-spacing: 1px;
  padding-left: 0px;
  margin-top: 0px;
  color: white;
  animation: fade-out linear;
  animation-timeline: view() ;
  animation-range: exit -200px;
  text-align: justify;
  text-justify: auto;
  
}
.content1 p{
  color: #ffffff;
  text-justify: auto;

}

.content1 .button1 {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 400px) {
 .bd{
  margin-top: 300vh;
 }
}
.content1 .button1 a {
  color: aliceblue;
  background-color: #00c3ff;
  border: 1px solid black;
  padding: 10px 35px;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 22px;
  border-radius: 20px;
  text-transform: uppercase;
  transition: background-color 0.3s, color 0.3s;
}

.content1 .button1 a:hover {
  background-color: aliceblue;
  color: #000000;
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .content1 .button1 a {
    padding: 8px 30px;
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .content1 .button1 a {
    padding: 7px 25px;
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .content1 .button1 a {
    padding: 6px 20px;
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .content1 .button1 a {
    padding: 5px 15px;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .content1 .button1 a {
    padding: 4px 10px;
    font-size: 12px;
  }
}

.content1 .social{
  margin-top: 60px;
  
}
.content1 .social a{
  
  color: #000000;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 22px;
  
  

}
.content1 .social a:hover{
  background-color: aliceblue;
  color: #0099ff;
  border-radius: 50%;
  
 
}
.image-section{
  width: 40%;
  float: right;
  padding: 0px 30px;
  position: absolute;
  padding-top: 100vh;
  margin-left: 1100px;
  margin-top: 170px;
  
  animation-timeline: view();
  animation: fade-out linear;
 
  animation-range: exit -200px;
  
}
@keyframes fade-in{
  from {scale: 0.8; opacity: 0;}
  to {scale: 1.1; opacity: 1;}

}
.content1 .social i{
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  border: 1px solid #223;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-bottom: 0px;
  
}

.bd{
  min-height: 75vh;
  /* display: flex; */
  /* flex-direction: column; */
  flex-wrap: wrap;
  column-gap: 65px;
  align-items: center;
  justify-content: center;
  background-color: #000000;

  margin-top: 100vh;
  /* background: linear-gradient(180deg, rgb(11, 27, 58)0, #04030c, #04030c, rgb(0, 0, 0)); */
  /* background: linear-gradient(180deg, #000000,  #171242, rgb(11, 27, 58), rgb(0, 0, 0)); */
  /* background: linear-gradient(180deg, rgb(11, 27, 58),  #171242, rgb(11, 27, 58), rgb(0, 0, 0)); */
  background: linear-gradient(180deg, rgb(5, 13, 29),  rgb(11, 27, 58), #171242, rgb(0, 0, 0));

}
@media only screen and (min-width: 425px) and (max-width: 867px) {
  .bd{
    margin-top: 250vh;
  
  }
  .content1 .button1 a:hover{
    padding: 5px 20px;
  }
}
/* Styles for screens between 348 pixels and 867 pixels */
@media only screen and (min-width: 348px) and (max-width: 867px) {
  .bd {
    /* Adjust properties to reduce gap */
    margin-top: 0; /* Example adjustment */
    padding-top: 0; /* Example adjustment */
  }
  
  .about1 {
    /* Adjust properties to reduce gap */
    margin-bottom: 0; /* Example adjustment */
    padding-bottom: 0; /* Example adjustment */
  }
}

.card-wrapper{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

}
.slide-container{
  max-width: 1120px;
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-wrap: wrap;
  
}
.slide-content{
  
  padding-left: 150px;
 
  
}

.card{
  width: 320px;
  height: 400px;
  border-radius: 25px;
  background-color: #ffffff;
  border: 3px solid rgb(255, 255, 255);
  margin-top: 20vh;
  
  
}
.card:hover{
  width: 340px;
  height: 420px;
  background-color: #ffffff;
  border:3px solid #ffffff ;
  transition: 220ms;
}
.image-content, .card-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 14px;
  margin-top: 0px;
}
.image-content{
  position: relative;
  row-gap: 5px;
  
  
}
.overlay{
 position: absolute;
  left: 0;
  right: 0;
  height: 180px;
  width: 100%;
  background-color: #006eff;
  border-radius: 25px 25px 0 25px;
  border: 1px solid white;
  
  
}

.card-image{
  position: absolute;
  height: 150px;
  width: 150px;
  
  

}
.card-image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  
  
  border: 4px solid rgb(255, 255, 255);
}
.name{
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  margin-top: 170px;
}
.card-content p{
  font-size: 30px;
  color: #000000;
  text-align: center;
  margin-top: 0px;
}
.facu{
  
  margin-bottom: 0px;
  
  color: #ffffff;

  font-size: 70px;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  

 

}
.card-content.social{
  margin-top: 60px;
  
}
.card-content .social a{
  
  color: #000000;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 22px;
  
  

}
.card-content .social a:hover{
  background-color: aliceblue;
  color: #0099ff;
  border-radius: 50%;
}
.card-content .social i{
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  border: 1px solid #223;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-bottom: 0px;
  
}
.ba{
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0px;
 
  width: 100%;
  
  
  
}
.wrapper .title{
  text-align: center;
}
.title h4{
  display: inline-block;
  padding: 20px;
  color: #ffffff;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 1.2px;
  border: 1px solid lightskyblue;
  border-radius: 15px;
  text-transform: uppercase;
  backdrop-filter: blur(15px);
  box-shadow: 0 10px 10px rgba(0,0,0,.1);
 
  margin-bottom: 10vh;
  animation: fade-out linear;
  animation-timeline: view();
   
  animation-range: exit -200px;
  

}

.title-co h4{
  display: inline-block;
  padding: 15px;
  color: #ffffff;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 1.2px;
  border: 1px solid lightskyblue;
  border-radius: 15px;
  text-transform: uppercase;
  backdrop-filter: blur(15px);
  box-shadow: 0 10px 10px rgba(0,0,0,.1);
 
  margin-bottom: 10vh;
  animation: fade-out linear;
  animation-timeline: view();
   
  animation-range: exit -200px;
  
}
h4 span {
  font-size: 24px;
  font-weight: bold;
  
}


@media (max-width: 600px) {
  
  .title h4{
    font-size: 18px;
    text-align: center;
    /* display: block; */
    margin: 0 auto;
  }
  .title-co h4{
    font-size: 18px;
    text-align: center;
    /* display: block; */
    margin: 0 auto;
  }
  h4 span {
    font-size: 18px;
    text-align: center;
    /* display: block; */
    margin: 0 auto;
  }
}


@media (min-width: 601px) and (max-width: 1024px) {
  .title h4{
    font-size: 18px;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  .title-co h4{
    font-size: 18px;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  h4 span {
    font-size: 22px;
    text-align: left;
    margin-left: 10px;
  }
}

@media (min-width: 1025px) {
  .title h4{
    font-size: 18px;
    text-align: center;
    /* display: block; */
    margin: 0 auto;
  }
  .title-co h4{
    font-size: 18px;
    text-align: center;
    /* display: block; */
    margin: 0 auto;
  }
  h4 span {
    font-size: 26px;
    text-align: left;
    margin-left: 20px;
  }
}
.wrapper .card-container1{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
  animation: fade-out linear;
  animation-timeline: view();
   
  animation-range: exit -200px;
  
  

}
.card-container1 .cards{
  position: relative;
    width: 350px;
    height: 500px;
    margin: 20px;
    overflow: hidden;
    box-shadow: 0 30px 30px -20px rgba(0, 0, 0, 1),
                inset 0 0 0 1000px rgba(67, 52, 109, .6);
    border-radius: 15px;
    display: flex;
    transition: transform 0.3s ease;
    
    
    
}
.card-container1 .cards1{
  position: relative;
    width: 250px;
    height: 350px;
    margin: 20px;
    overflow: hidden;
    box-shadow: 0 30px 30px -20px rgba(0, 0, 0, 1),
                inset 0 0 0 1000px rgba(67, 52, 109, .6);
    border-radius: 15px;
    display: flex;
    transition: transform 0.3s ease;
    
    
    
}

.card-container1 .cards:hover{
  transform: scale(1.1);
  
}
.card-container1 .cards1:hover{
  transform: scale(1.1);
  
}
.cards .imbx{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx img{
  width: 200%;
  height: auto;
  
}
.cards .imbx1{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.cards1 .imbx1{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx1 img{
  width: 225%;
  height: auto;
  
  
}
.cards1 .imbx1 img{
  width: 470%;
  height: auto;
  
}
.cards .imbx2{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.cards1 .imbx2{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.cards .imbx21{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.cards .imbx21 img{
  width: 180%;
  height: auto;
  
}

.cards1 .imbx2 img{
  width: 135%;
  height: auto;
  
}
.cards .imbx2 img{
  width: 250%;
  height: auto;
  
}
.cards .imbx5{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx5 img{
  width: 200%;
  height: auto;
  
}
.cards .imbx6{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx6 img{
  width: 250%;
  height: auto;
  
}
.cards .imbx7{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx7 img{
  width: 270%;
  height: auto;
  
}
.cards .imbx8{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx8 img{
  width: 255%;
  height: auto;
  
}
.cards .imbx9{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx9 img{
  width: 270%;
  height: auto;
  
}
.cards .imbx11{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx11 img{
  width: 200%;
  height: auto;
  
}
.cards .imbx12{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx12 img{
  width: 240%;
  height: auto;
  
}
.cards .imbx13{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx13 img{
  width: 210%;
  height: auto;

  
}
.cards .imbx16{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx16 img{
  width: 180%;
  height: auto;

  
}
.cards1 .imbx14{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.cards1 .imbx14 img{
  width: 160%;
  height: auto;
  

  
}
.cards1 .imbx15{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.cards1 .imbx15 img{
  width: 200%;
  height: auto;
  

  
}
.cards .imbx30{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx30 img{
  width: 240%;
  height: auto;
  
}
.cards .imbx31{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx31 img{
  width: 200%;
  height: auto;
  
}
.cards1 .imbx151 img{
  width: 99%;
  height: auto;
  

  
}
.cards .contents{
  position: absolute;
  bottom: -160px;
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(15px);
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: bottom 0.5s;
  transition-delay: 0.65s;
}
.cards:hover .contents{
  bottom: 0;
  transition-delay: 0s;
}
.cards1 .contents{
  position: absolute;
  bottom: -160px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(15px);
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: bottom 0.5s;
  transition-delay: 0.65s;
}
.cards1:hover .contents{
  bottom: 0;
  transition-delay: 0s;
}
.contents .contentbx h3{
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  font-weight: 1000;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 15px;
  line-height: 1.1em;
  transition: 0.5s;
  transition-delay: 0.4s;
  opacity: 0;
  transform: translateY(-10px);
}
.cards:hover .contents .contentbx h3{
  opacity: 1;
  transform: translateY(0);
}
.cards1:hover .contents .contentbx h3{
  opacity: 1;
  transform: translateY(0);
}
.contents .contentbx h3 span{
  font-size: 12px;
  font-weight: 300;
  text-transform: initial;
}
.contents .social{
  margin-top: px;
  
}
.contents .social a{
  
  color: #000000;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 22px;
  
  

}
.contents .social a:hover{
  background-color: aliceblue;
  color: #0099ff;
  border-radius: 50%;
  
 
}
.contents .social i{
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  border: 1px solid #223;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-bottom: 0px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.2s * var(--i));
  
}
.cards:hover .social i{
  transform: translateY(0);
    opacity: 1;
}
.cards .imbx3{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.imbx3 img{
  
  width: 300%;
  height: auto;
  
}

.cd{
  display: flex;
  justify-content: center;
  
  margin-top: 0px;
  height: 110vh;
  width: 100%;
  background: linear-gradient(180deg, rgb(101, 10, 206), #362f74, rgb(55, 20, 83), rgb(32, 2, 23));
}
.contactus{
  position: relative;
  width: 100%;
 
  
}
.contactus .contitle{
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactus .contitle h2{
  color: #ffffff;
  font-weight: 500;
}

.form{
  grid-area: form;
}
.info{
  grid-area: info;
}

.map{
  grid-area: map;
}
.contact{
  padding: 40px;
  background-color: white;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.15);
}

.boxy{
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 5fr 4fr;
  grid-template-areas: 
  "form info"
  "form map";
  grid-gap:20px;
  margin-top: 20px;
}
.contact h3{
  color: #053281;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: "Anton", sans-serif;
}
.formBx{
  position: relative;
  width: 100%;
}
.formBx .row50{
  display: flex;
  gap: 20px;
}
.inputbx{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 50%;

}
.formBx .row100 .inputbx{
  width: 100%;
  

}
.inputbx span{
  color: #053281;
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 500;
}
.inputbx input{
  padding: 10px;
  font-size: 1.1rem;
  outline: none;
  border: 1px solid #333;
}
.inputbx textarea{
  padding: 10px;
  font-size: 1.1rem;
  outline: none;
  border: 1px solid #333;
  min-height: 220px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}
.inputbx input[type="submit"]{
  background-color: #053281;
  color: #ffffff;
  border: none;
  font-size: 1.1em;
  max-width: 120px;
  font-weight: 500;
  cursor: pointer;
  padding: 14px 15px;
  border-radius: 10px;
}
.inputbx input[type="submit"]:hover{
  background-color: #006eff;
}
.inputbx ::placeholder{
  color: rgb(194, 190, 190);
}
.info{
  background: linear-gradient(45deg, #37D5D6, #36096D);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
}
.info h3{
  color: #ffffff;
}
.info .infobx div{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
} 
.info .infobx div span{
  min-width: 40px;
  height: 40px;
  color: #ffffff;
  background-color: #0a2333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  border-radius: 50%;
  margin-right: 15px;

}
.info .infobx div p{
  color: #ffffff;
  text-align: justify;
}
.info .infobx div a{
  color: #ffffff;
  text-decoration: none;
}
.map{
  padding: 0;
  background: linear-gradient(45deg, #37D5D6, #36096D);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
}
.map iframe{
  width: 100%;
  height: 100%;
}
.infobx .social{
  margin-top: 60px; 
  padding: 2px;
  
}
.infobx .social a{
  
  color: #000000;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 22px;
  
  

}
.infobx .social a:hover{
  background-color: aliceblue;
  color: #03b5fc;
  border-radius: 50%;
  
 
}
.infobx .social i{
  height: 40px;
  width: 40px;
  
  background-color: #000000;
  border: 1px solid #223;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-bottom: 0px;
  
}

.foot{
  position: relative;
  width: 100%;
  
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(45deg, #000000, #150d58);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  position: absolute;
}
.foot .social{
  margin-top: 2px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
}
.foot .memu{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.foot .social a{
  
  color: #ffffff;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 22px;
  transition: 0.5s;
  
  

}
.foot .social a:hover{
  
  color: #ee08a1;
  border-radius: 50%;
  transform: translateY(-10px);
  
 
}
.foot .social i{
  height: 40px;
  width: 40px;
  
  background-color: #000000;
  border: 1px solid #223;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-bottom: 0px;
  
}
.foot .memu li{
  list-style: none;
}
.foot .memu li a{
  font-size: 2em;
  color: #ffffff;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
}
.foot p{
  color: #ffffff;
}

@media (max-width: 991px)
{
  body{
    background-color: #000000
    
  }
  .contactus{
    padding: 20px;
  }
  .boxy{
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "forms"
    "info"
    "map";
  }
  /* .ba{
    height: 1300vh;

  } */
  .boxy{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  .bd{
    width: 100%;
  }
}
@media only screen and (max-width: 600px){
  .ba{
    background: #000000;
  }
  .bd{
    background: #000000;
  }
}


@media only screen and (max-width:600px){
  .overlay video{
    width: 100%;
    height: auto;
  }
}
@media (max-width: 952px){
  .logo img{
    font-size: 50px;
    
  }
 
}
@media (max-width: 867px){
 
  .bd{
    margin-top: 300vh;
  }
  .ba{
    margin-top: 0vh;
  }
}

@media (max-width: 858px){
 
  /* .ba{
    height: 1000vh;
  } */
}
@media screen and (max-width: 1379px) {
  /* .ba{

    height: 780vh;
    
  }
  */
}
@media screen and ((min-width: 1380px) and (max-width: 1880px)) {
  .cd{
    margin-top: 0vh;
  }
  /* .ba{
    height: 430vh;
  } */
}

@media screen and (max-width: 1790px) {
  .glassname{
    margin-left: 20%;
    width: 950px;
  }
  .image-section{
    
    margin-left: 50%;
  }
}

@media screen and (max-width: 1200px) {
  .content{
    display: none;
  }
  .content h1 {
    font-size: 80px;
    padding-left: 50px;
  }
  
  .content h2 {
    font-size: 80px;
    padding-left: 50px;
  }
  
  .content h3 {
    padding-left: 50px;
  }
  
  .glassname {
    display: none;
    width: 800px;
    height: 400px;
    margin-left: 200px;
    margin-top: 200px;
  }
  /* .ba{
    height: 1000vh;
  } */
  .image-section{
    margin-left: 50%;
  }
  .about1 h1{
    margin-left: 0px;
    padding-left: 300px;
  }
  .cd{
    margin-top: 0%;
  }
}

@media screen and (max-width: 768px) {
  .content h1 {
    font-size: 60px;
    padding-left: 20px;
  }
  
  .content h2 {
    font-size: 60px;
    padding-left: 20px;
  }
  
  .content h3 {
    padding-left: 20px;
  }
  
  .glassname {
    width: 600px;
    height: 300px;
    margin-left: 100px;
    margin-top: 150px;
  }
  /* .ba{
    height: 1200vh;
  } */
}
@media screen and (max-width: 768px) {
  .glassname {
    width: 90%; 
    height: 300px;
    margin: 100px auto; 
  }
}

body, .main {
  background-color: #000000; 
}

@media screen and (max-width: 991px) {
  .main{
    width: 100%;
  }
  .about1 {
    padding: 50px 20px;
    height: 100vh; 
    /* margin-top: 100vh;  */
  }
  .about1 h1 {
    font-size: 40px; 
    padding-left: 20px; 
    margin-top: 20px; 
  }
  .wrapper {
    padding: 0; 
    margin: 20px auto;
    width: 100%;
   
  }
  .content1 {
    width: 100%;
    padding: 0 20px;
  }
  .image-section {
    display: none; 
  }

}
@media screen and (max-width: 425px) {
  .card-wrapper{
    margin-top: 30vh;
  }
  .contact form{
    display: flex;
    
    flex-wrap: wrap;
  }
  .formBx .row50{
    display: flex;
    flex-wrap: wrap;
    width: auto;
    height: auto;
  }
  .footer{
    margin-top: 70vh;

  }
}

@media screen and (max-width: 600px) {
  .about1 h1 {
    font-size: 30px; 
    padding-left: 10px; 
    margin-top: 10px; 
  }
  .content1 {
    padding: 0 10px; 
  }
  .content1 .social a {
    font-size: 18px; 
  }

}






.fac_contain{
  display: flex;
  flex-direction: column;

}

.fac_container{
  display: flex;
  flex-direction: column;
}
.fac_name
{
  background: linear-gradient(180deg, rgb(14, 68, 184), rgb(8, 63, 134), #170e66, #0d1275);
  z-index: 101;
}
ul {
  list-style: none;
}
a {
  text-decoration: unset;
}
a:hover {


  text-decoration: underline;
  color: darkblue;
}
ul > li > a {
  color:#fff;
}
ul > li {
  line-height: 1.5em;
}
.footer {
  margin-top: 200vh;
  display: block;
  width: 100%;
  position: relative;
  padding-top: 150px;
  padding-right: 25px;
  padding-bottom: 50px;
  padding-left: 25px;
  background: linear-gradient(45deg, rgb(0, 0, 0), rgb(0, 19, 44), #0a005f, #000794);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.footer-start {
  display: flex;
  position: relative;
  width: 90%;
  max-width: 1280px;
  margin: auto;
  background: linear-gradient(45deg, rgb(1, 0, 2), rgb(27, 0, 34), #2d0042, #40006b);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  border-radius: 14px;
  justify-content: space-between;
  align-items: center;
  padding: 70px 100px;
}
.start-learning {
  position: absolute;
  left: 0;
  right: 0;
  top: -150px;
}
.footer .inner {
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  justify-content: space-between;
  max-width: 1180px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  color: #fff;
}
.main-logo {
  position: relative;
  display: flex;
  align-items: center;
}
.main-logo .logo {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.main-logo .logo > img {
  display: block;
  width: 100%;
  min-width: 40px;
}
.logo-info {
  text-align: left;
  line-height: 20px;
}
.text {
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  letter-spacing: .18em;
  font-weight: 600;
}
.copyright {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
}
.is-logo > a {
  text-decoration: unset;
}


.footer .column {
  width: 100%;
  font-size: 14px;
  text-align: left;
}
.footer .column .column-title {
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 16px;
}
.footer .column .column-title:not(:first-child) {
  margin-top: 1.5em;
}

.illustration {
  position: absolute;
  top: -65px;
  left: 8%;
  width: 150px;
  height: 150px;
}
a.button {
  display: inline-flex;
  position: relative;
  height: 60px;
  margin-right: 20px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  background: #fff;
  color: #705df2;
  border-radius: 36px;
  box-shadow: 0px 24px 74px rgb(60 0 189 / 20%);
  font-size: 16px;
  font-weight: 700;
  padding: 0 30px;
  text-decoration: unset;
}
.section-title {
  font-size: 44px;
  font-weight: 700;
  color: #fff;
}
h3.section-sub-title {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
}
.section-sub-title span{
  opacity: 0.6;
}
h3.section-sub-title strong {
  font-weight: 600;
}


@media only screen and (max-width: 990px) and (min-width: 200px) {
  body {
      height: 200vh;
      overflow-x: hidden;
      overflow-y: scroll;
  }
  .footer .inner {
      display: grid;
      grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
  }
  .start-learning {
      position: unset;
  }
  .footer-start {
      width: 100%;
      display: block;
      padding: 30px 20px;
      margin: 30px auto;
  }
  .section-title {
      font-size: 30px;
  }
  h3.section-sub-title {
      font-size: 18px;
  }
  .footer {
      padding-top:0;
      margin-top: 120vh;
  }
  a.button {
      height: 50px;
      margin-top: 10px;
  }
  .column.is-logo {
      order: 4;
  }
} 
.column .social{
  margin-top: 60px;
  
}
.column .social a{
  
  color: #000000;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 22px;
  
  

}
.column .social a:hover{
  background-color: aliceblue;
  color: #0099ff;
  border-radius: 50%;
  
 
}
.column .social i{
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  border: 1px solid #223;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-bottom: 0px;
  
}
.membership {
  
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  
  
  height: 100vh;
}
.membership h1{
  color: #ffffff;
  
}
.membership p{
  color: #ffffff;
  align-items: center;
  
}

.containerapp {
  display: flex;
  flex-direction: column;
  min-height: 120vh;
  width: 100%;
  

}

.main-contentapp {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-content: center; 
  /* background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%); */
  background: linear-gradient(180deg, #000000,  #171242, rgb(11, 27, 58), rgb(0, 0, 0));
  


 
}



.wrappermem{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10vh;
  margin-bottom: 20vh;
  
}
.cardmem {
  min-height: 100%;
  background-color: black;
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  padding: 30px;
  border-radius: 10px;
  box-sizing: border-box;
  color: #FFF;
  margin: 20px;
  overflow: hidden; 
  display: inline-block; 
  vertical-align: top; 
  background: linear-gradient(45deg, #fc00ff, #00dbde);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  transform: scale(1);
 
}
.cardmem img{
  width: 100%;
  height: 200px; 
  object-fit: cover;
}
@media (max-width: 768px) {
  .cardmem {
    width: calc(50% - 40px); 
  }
  
  @media (max-width: 480px) {
  .cardmem {
    width: calc(100% - 70px);  
  }
}
}

.cardmem:hover{
  scale: 1.05;
  transition: 700ms;
  background: linear-gradient(45deg, #00dbde, #fc00ff);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
}
.cardmem h3{
  font-size: xx-large;
}
.cardmem p{
  font-size: 20px;
}

.cardmem:nth-child{
  background-color: #000000;
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
}
.card-titlemem{
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-align: center;
}
.card-contentmem{
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 1.5;
}
.card-btnmem{
  all: unset;
  display: block;
  margin-left: auto;
  border: 2px solid #FFF;
  padding: 10px 15px;
  border-radius: 50%;
  font-size: 30px;
  font-weight: 600;
  transition: all 0.5s;
  cursor: pointer;
  letter-spacing: 1.2px;
  margin-top: 10px;
  color: white;
}
.card-btnmem:hover{
 color:#02b875;
 background: #FFF;
}
.cardmem:nth-child(2) .card-btnmem:hover{
 color:#4181ee;
 background: #FFF;
}
.cardmem:last-child .card-btnmem:hover{
 color:#673ab7;
  background: #FFF;
}
.cardmem span{
  margin-left: 350px;
  font-size: 50px;
  
}
main {
  width: 100%;
  min-height: 120vh;
  overflow: hidden;
  background: linear-gradient(45deg, rgb(0, 183, 255), rgb(2, 139, 202), #b700ff, #5c005f);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxlo {
  position: relative;
  width: 100%;
  max-width: 1020px;
  height: 640px;
  background-color: #fff;
  border-radius: 3.3rem;
  box-shadow: 0 60px 40px -30px rgba(0, 0, 0, 0.27);
  
}

.inner-boxlo {
  position: absolute;
  width: calc(100% - 4.1rem);
  height: calc(100% - 4.1rem);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.forms-wraplo {
  position: absolute;
  height: 100%;
  width: 45%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  transition: 0.8s ease-in-out;
}

form {
 
  width: 100%;
  
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  transition: opacity 0.02s 0.4s;
}

form.sign-up-form {
  opacity: 0;
  pointer-events: none;
}

.logolo {
  display: flex;
  align-items: center;
}

.logolo img {
  width: 27px;
  margin-right: 0.3rem;
}

.logolo h4 {
  font-size: 1.1rem;
  margin-top: -9px;
  letter-spacing: -0.5px;
  color: #151111;
}

.heading h2 {
  font-size: 2.1rem;
  font-weight: 600;
  color: #151111;
}

.heading h6 {
  color: #bababa;
  font-weight: 400;
  font-size: 0.75rem;
  display: inline;
}

.toggle {
  color: #151111;
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: 500;
  transition: 0.3s;
}

.toggle:hover {
  color: #8371fd;
}

.input-wrap {
  position: relative;
  height: 37px;
  margin-bottom: 2rem;
}

.input-field {
  position: absolute;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: 1px solid #bbb;
  padding: 0;
  font-size: 0.95rem;
  color: #151111;
  transition: 0.4s;
}

label {
  
  left: 0;
  top: 50%;
  
  
  color: #bbb;
  pointer-events: none;
  transition: 0.4s;
}

.input-field.active {
  border-bottom-color: #151111;
}

.input-field.active + label {
  font-size: 0.75rem;
  top: -2px;
}

.sign-btn {
  display: inline-block;
  width: 100%;
  height: 43px;
  background-color: #151111;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 0.8rem;
  font-size: 0.8rem;
  margin-bottom: 2rem;
  transition: 0.3s;
}

.sign-btn:hover {
  background-color: #8371fd;
}

.text {
  color: #bbb;
  font-size: 0.7rem;
}

.text a {
  color: #bbb;
  transition: 0.3s;
}

.text a:hover {
  color: #8371fd;
}

main.sign-up-mode form.sign-in-form {
  opacity: 0;
  pointer-events: none;
}

main.sign-up-mode form.sign-up-form {
  opacity: 1;
  pointer-events: all;
}

main.sign-up-mode .forms-wrap {
  left: 55%;
}



.carousel {
  position: absolute;
  height: 100%;
  width: 55%;
  left: 46%;
  top: 0;
  background: linear-gradient(45deg, rgb(0, 183, 255), rgb(2, 139, 202), #b700ff, #5c005f);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  border-radius: 2rem;
  display: grid;
  grid-template-rows: auto 1fr;
  padding-bottom: 2rem;
  overflow: hidden;
  transition: 0.8s ease-in-out;
}

.images-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.image {
  width: 100%;
  height: 30vh;
  grid-column: 1/2;
  grid-row: 1/2;
  opacity: 0;
  transition: opacity 0.3s, transform 0.5s;
}

.img-1 {
  transform: translate(0, -50px);
}

.img-2 {
  transform: scale(0.4, 0.5);
}

.img-3 {
  transform: scale(0.3) rotate(-20deg);
}

.image.show {
  opacity: 1;
  transform: none;
}

.text-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.text-wrap {
  max-height: 2.2rem;
  overflow: hidden;
  margin-bottom: 2.5rem;
}

.text-group {
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: translateY(0);
  transition: 0.5s;
}

.text-group h2 {
  line-height: 2.2rem;
  font-weight: 600;
  font-size: 1.6rem;
}

.bullets {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bullets span {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #aaa;
  margin: 0 0.25rem;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;
}

.bullets span.active {
  width: 1.1rem;
  background-color: #151111;
  border-radius: 1rem;
}

@media (max-width: 850px) {
  .boxlo {
    height: auto;
    max-width: 550px;
    overflow: hidden;
  }

  .inner-boxlo {
    position: static;
    transform: none;
    width: revert;
    height: revert;
    padding: 2rem;
  }

  .forms-wraplo {
    position: revert;
    width: 100%;
    height: auto;
  }

  form {
    max-width: revert;
    padding: 1.5rem 2.5rem 2rem;
    transition: transform 0.8s ease-in-out, opacity 0.45s linear;
  }

  .heading {
    margin: 2rem 0;
  }

  form.sign-up-form {
    transform: translateX(100%);
  }

  main.sign-up-mode form.sign-in-form {
    transform: translateX(-100%);
  }

  main.sign-up-mode form.sign-up-form {
    transform: translateX(0%);
  }

  .carousel {
    position: revert;
    height: auto;
    width: 100%;
    padding: 3rem 2rem;
    display: flex;
  }

  .images-wrapper {
    display: none;
  }

  .text-slider {
    width: 100%;
  }
}

@media (max-width: 530px) {
  main {
    padding: 1rem;
  }

  .boxlo {
    border-radius: 2rem;
  }

  .inner-boxlo {
    padding: 1rem;
  }

  .carousel {
    padding: 1.5rem 1rem;
    border-radius: 1.6rem;
  }

  .text-wrap {
    margin-bottom: 1rem;
  }

  .text-group h2 {
    font-size: 1.2rem;
  }

  form {
    padding: 1rem 2rem 1.5rem;
  }
}
.imgBL{
  position: relative;
  opacity: 1;
}

@media (max-aspect-ratio: 16 / 9){
  
  #Time-title{
    
    margin-top: 100px;
    
  }
    
  
  .wrapper{
    text-align: center;
    justify-content: center;
  }


  /* .title h4{
    margin-top: 138vh;
  } */
  .stay h1{
    font-size: small;
  }
  .navbar li{
    size: 100%;
  }
  .contact{
    padding: 20px;
  }
  .inputbx input {
    width: 100%;
  }
  .inputbx textarea {
    min-height: 0;
  }
  .contact form{
    padding: 0%;
    width: 100%;
    height: 600px;
    
  }
  .column-title{
    display: none;
  }
  .illustration{
    top: -90px;
  }
 
video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #000000;
}
.about1{
  height: 100vh;
  width: 100%;
}
.contactmap{
  display: none;
}

.cd{
  height: 180vh;
}

}

.containereve {
  position: relative;
  max-width: 700px;
  width: 100%;
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-top: 3vh;
  margin-bottom: 20vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  
  
}
.containereve header {
  font-size: 1.5rem;
  color: #333;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
.containereve .form {
  margin-top: 30px;
}
.form .input-boxeve {
  width: 100%;
  margin-top: 20px;
}
.input-boxeve label {
  color: #333;
}
.form :where(.input-boxeve input, .select-box) {
  position: relative;
  height: 50px;
  width: 100%;
  outline: none;
  font-size: 1rem;
  color: #707070;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 0 15px;
}
.input-boxeve input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.form .columneve {
  display: flex;
  column-gap: 15px;
}
.form .gender-box {
  margin-top: 20px;
}
.gender-box h3 {
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
}
.form :where(.gender-option, .gender) {
  display: flex;
  align-items: center;
  column-gap: 50px;
  flex-wrap: wrap;
}
.form .gender {
  column-gap: 5px;
}
.gender input {
  accent-color: rgb(130, 106, 251);
}
.form :where(.gender input, .gender label) {
  cursor: pointer;
}
.gender label {
  color: #707070;
}
.address :where(input, .select-box) {
  margin-top: 15px;
}
.select-box select {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  color: #707070;
  font-size: 1rem;
}
.form button {
  height: 55px;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background: rgb(130, 106, 251);
}
.form button:hover {
  background: rgb(88, 56, 250);
}

@media screen and (max-width: 500px) {
  .form .columneve {
    flex-wrap: wrap;
  }
  .form :where(.gender-option, .gender) {
    row-gap: 15px;
  }
}
.event-name{
  margin-top: 10vh;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
}
.aboutsec{
  margin-top: 10vh;
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  
  

  
}
.Aboutt img {
  max-width: 100%; 
  height: auto;
  display: block;
  margin: 0 auto;
}

.about-paragraph{
  font-size:20px;
  font-weight: lighter;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding: 20px;
  
  text-align: justify;
  text-justify: auto;
}
.about-heading{
  align-content: center;
 
  font-size: 50px;
  margin-bottom: 20px;
}
.about-heading span{
  color: #0099ff;
}
.recruit{
  margin-top: 10vh;
}
@media screen and (max-width: 880px) {
  /* .ba{
     height: 1200vh;
  } */
}
@media screen and (min-width: 880px) and (max-width: 1200px) {
  /* .ba{
    height: 800vh;
  } */
}
@media screen and (min-width: 0px) and (max-width: 1200px) {
  /* .main-contentapp{
    width: 100%;

  } */
}
#tilt-card {
  box-shadow: none !important;
  background-color: none;
 

}
#scroll-container {
  width: 100%;
  overflow: hidden;
}

#scroll-text {
  margin-top: 10vh;
  font-size: 250px;
  
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  
  -moz-animation: my-animation 10s linear infinite;
  -webkit-animation: my-animation 10s linear infinite;
  animation: my-animation 10s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  0% { -moz-transform: translateX(100%); }
  100% { -moz-transform: translateX(-100%); }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  0% { -webkit-transform: translateX(100%); }
  100% { -webkit-transform: translateX(-100%); }
}

@keyframes my-animation {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.sl{
  
  margin-top: 10vh;
  display: grid;
  
}
.sl2{
  
  
  display: grid;
  
}
.sl1{
  margin-top: 10vh;
  /* background: linear-gradient(45deg, #F5F7F6, #5CA0F2); */
 
  
  display: grid;
  
}

.slider{
  background: linear-gradient(45deg, #F5F7F6, #5CA0F2);
  border-radius: 50px;
  height: 400px;
  margin: auto;
  position: relative;
  width: 95%;
  display: grid;
  place-items: center;
  overflow: hidden;
  
  

}
.slider2{
  /* background: linear-gradient(45deg, #F5F7F6, #5CA0F2); */
  border-radius: 50px;
  height: 300px;
  margin: auto;
  position: relative;
  width: 95%;
  display: grid;
  place-items: center;
  overflow: hidden;
  
  

}
.sl1 .slider{
  border-radius: 50px;
  height: 450px;
  margin: auto;
  position: relative;
  width: 95%;
  display: grid;
  place-items: center;
  overflow: hidden;
  background: linear-gradient(45deg, #F5F7F6, #5CA0F2);
  
  

}

.slide-track{
  display: flex;
  width: calc(500px * 18);
  animation: scroll 20s linear infinite;

}
.slide-track:hover{
  animation-play-state:paused ;
}
@keyframes scroll {
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(calc(-500px * 9));
  }
}
.slide{
  height: 200px;
  width: 500px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}

.slide img{
  width: 100%;
  transition: transform 1s;
  
}
.sl1 .slide img{
  width: 65%;
  transition: transform 1s;
  height: 400px;
  
}
.slide img:hover{
  transform: translateZ(10px);
  
}
.slider::before,
.slider::after{
  background-color: linear-gradient(to right, rgba(255,255,255,1) 0%,
  rgba(255,255,255,0) 100%);
  content: '';
  position: absolute;
  width: 15%;
  z-index: 2;
}
.slider::before{
  left: 0;
  top: 0;
}
.slider::after{
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.container-load{

  position: absolute;
  left: 50%;
  top: 50%;
}

.circle-load{
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 50%;
  position: absolute;
  animation: anim 2s linear infinite;

}
.circle-load:nth-child(2){
  
  animation-delay: -0.3s;

}
.circle-load:nth-child(3){
  
  animation-delay: -0.6s;

}
@keyframes anim{
  0%{
    transform-origin: 400% 50%;
    transform: rotate(0);

  }
  50%{
    transform-origin: 400% 50%;
    transform: rotate(360deg);
  }
  50.1%{
    transform-origin: -300% 50%;
    transform: rotate(0deg);
  }
  100%{
    transform-origin: -300% 50%;
    transform: rotate(-360deg);
  }
}
.stay{
  display: flex;
  justify-content: center;
}
.stay h1{
  color: white;
  margin-top: 70vh;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  
  
}
.sect h1{
  margin-top: 10vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.main-about {
  margin-top: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-about video {
  height: 60vh;
  width: 60vw;
 
}
.mission{
  margin-top: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.mission h1{
  color: white;
  font-size: 50px;
}
.mission h1 span{
  color: #0099ff;
}
.mission-paragraph{
  color: white;
  font-size:20px;
  font-weight: lighter;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  padding-top: 20px;
  text-align: justify;
  text-justify: auto;
  margin-bottom: 50px;
}
.image-mission img{
  height: 70%;
  width: 70%;
}
.image-mission{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
}
.vision{
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.vision h1{
  color: white;
  font-size: 60px;
}
.vision h1 span{
  color: #0099ff;
}
.ideology{
  margin-top: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ideology h1{
  color: white;
  font-size: 50px;
}
.ideology h1 span{
  color: #0099ff;
}

.title-core{
  display: inline-block;
  padding: 20px;
  color: #ffffff;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 1.2px;
  border: 1px solid lightskyblue;
  border-radius: 15px;
  text-transform: uppercase;
  backdrop-filter: blur(15px);
  box-shadow: 0 10px 10px rgba(0,0,0,.1);
 
  margin-bottom: 10vh;
  animation: fade-out linear;
  animation-timeline: view();
   
  animation-range: exit -200px;
 

}
.footer-child{
  margin-top: 50vh;
  display: block;
  width: 100%;
  position: relative;
  padding-top: 150px;
  padding-right: 25px;
  padding-bottom: 50px;
  padding-left: 25px;
  background: linear-gradient(45deg, rgb(0, 0, 0), rgb(0, 19, 44), #0a005f, #000794);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@media only screen and (max-width: 412px) and (max-height: 870px) {
  /* video{
    width: 110%;
  } */
}
@media only screen and (max-width: 320px) {
  .Blog-post h3{
    font-size: 10px;
  }
  
  #Time-title{
    font-size: 10px;
  }
  .Aboutt{
    padding-left: 40px;
    padding-right: 40px;
  }

.about-heading{
  font-size: 100%;
}
.about-paragraph{
  padding: 0px;
  font-size: 10px;
  /* padding-right: 50px; */

}
.mission h1, .vision h1, .ideology h1{
  font-size: 100%;
}
.mission-paragraph{
  padding: 0px;
  font-size: 10px;
  /* padding-right: 50px; */
}
.Aboutt img{
  
  /* width: 80%; */
  margin: 0%;
}
.contactus{
  padding: 0px;
}

}
@media only screen and (max-width: 600px){
  /* .Aboutt{
    padding-left: 40px;
    padding-right: 40px;
  } */
  .about-heading{
    font-size: 100%;
  }
  .about-paragraph{
    padding: 0px;
    font-size: 10px;
    /* padding-right: 50px; */
  
  }
  .mission h1, .vision h1, .ideology h1{
    font-size: 100%;
  }
  .mission-paragraph{
    padding: 0px;
    font-size: 10px;
    /* padding-right: 50px; */
  }
  .Aboutt img{
    
    /* width: 80%; */
    margin: 0%;
  }
  .contactus{
    padding: 0px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .footer{
    margin-top: 600vh;
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    
    bottom: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    
    bottom: 0;
    width: 100%;

  }
}

@media only screen and (max-width: 1024px) {
  .footer {
    
    bottom: 0;
    width: 100%;
  }
}





@media only screen and (max-width: 480px) {
  .footer {
    bottom: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .footer {
    bottom: 0;
    width: 100%;
  }
  .content h1 {
    font-size: 60px;
    padding-left: 20px;
  }
  .content h2 {
    font-size: 60px;
    padding-left: 20px;
  }
  .glassname {
    width: 90%; 
    height: 300px;
    margin: 100px auto; 
  }
  /* .ba {
    height: 1200vh;
  } */
}
@media only screen and (max-width: 914px) {
  .title h4{
    margin-top: 50vh;
  }
}

/* Styles for screens smaller than 870 pixels */
@media only screen and (max-width: 870px) {
  /* Add your CSS rules here */
  .your-element-class {
    width: 100%;
    /* Adjust other properties as needed */
  }
}
@media only screen and (max-width: 1024px) {
  .footer {
    bottom: 0;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .content {
    display: none;
  }
  .content h1 {
    font-size: 80px;
    padding-left: 50px;
  }
  .glassname {
    display: none;
    width: 800px;
    height: 400px;
    margin-left: 200px;
    margin-top: 200px;
  }
  /* .ba {
    height: 1000vh;
  } */
}
@media only screen and (max-width: 600px) {

  .about1 h1 {
    font-size: 30px;
    padding-left: 10px;
    margin-top: 10px;
  }
  .content1 {
    padding: 0 10px;
  }
  .content1 .social a {
    font-size: 18px;
  }
  /* .ba {
    height: 1300vh;
  } */
}
@media only screen and (max-width: 412px) and (max-height: 870px) {
  /* video {
    width: 110%;
  } */
}
@media (max-width: 530px) {
  main {
    padding: 1rem;
  }
  .boxlo {
    border-radius: 2rem;
  }
  .carousel {
    padding: 1.5rem 1rem;
    border-radius: 1.6rem;
  }
  .text-wrap {
    margin-bottom: 1rem;
  }
  .text-group h2 {
    font-size: 1.2rem;
  }
  form {
    padding: 1rem 2rem 1.5rem;
  }
}
@media screen and (max-width: 991px) {
  .main {
    width: 100%;
  }
  .about1 {
    padding: 50px 20px;
    height: 250vh;
    /* margin-top: 100vh; */
  }
  .about1 h1 {
    font-size: 40px;
    padding-left: 20px;
    margin-top: 20px;
  }
  .wrapper {
    padding: 0;
    margin: 20px auto;
  }
  .content1 {
    width: 100%;
    padding: 0 20px;
  }
  .image-section {
    display: none;
  }
}


.input-boxeve textarea{
  height: 100px;
  width: 90%;
  
}
.Blog {
  margin-top: 10vh;
  color: white;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center; 
  /* background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%); */
  z-index: 1;
}

.Main-heading {
  font-size: 2.5em;
  color: #fff;
  margin-bottom: 1vh;
  text-align: center;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
}

.Blog-heading-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo-and-heading {
  display: flex;
  align-items: center;
}

.small-logo {
  margin-right: 10px;
}

.small-logo img {
  width: 40px;
  height: auto;
}

.Blog-heading {
  color: #fff;
}

.Blog-heading span {
  color: #0099ff;
}

.heading-line {
  width: 100%;
  height: 0.5px;
  background-color: rgb(255, 255, 255);
  margin: 10px 0;
}

.search-bar {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 250px;
  margin-left: auto;
}

.Follow-button {
  margin-top: 20px;
  text-align: center;
}

.follow-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.follow-button:hover {
  background-color: #0056b3;
}

.heading-line1 {
  width: calc(70% - 50px);
  height: 0.5px;
  background-color: rgb(255, 255, 255);
  margin: 10px 0;
  margin-bottom: 10vh;
}

.Blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 70%;
  margin-bottom: 10vh;
}

.Blog-post {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  flex-wrap: wrap;
  width: 50%;
  
}

.Post-content {
  flex: 1;
  margin-right: 20px;
}

.Post-image {
  flex-shrink: 0;
  width: 150px;
  margin-top: 20px;
}

.Post-image img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.Blog-post h3 {
  margin-top: 0;
  font-size: large;
}

.Blog-post p {
  margin: 10px 0;
  text-align: justify;
}

.read-more-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.read-more-button:hover {
  background-color: #0056b3;
}

.highlight {
  background-color: rgb(255, 255, 255);
  color: black;
}

@media (max-width: 768px) {
  .Blog-container {
    width: 90%;
  }

  .Blog-post {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .Post-content {
    margin-right: 0;
    text-align: center;
  }

  .Post-image {
    margin-top: 10px;
    width: 100%;
  }

  .Blog-heading-container {
    flex-direction: column;
    align-items: center;
  }

  .search-bar {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 768px) {
  .Main-heading {
    font-size: 2em;
    margin-bottom: 5vh;
  }
}

@media (max-width: 480px) {
  .Main-heading {
    font-size: 1.5em;
    margin-bottom: 3vh;
  }
}

@media (max-width: 768px) {
  .heading-line1 {
    width: calc(100% - 50px);
  }
}
.member {
  color: white;
  padding: 0 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Noto Sans JP", sans-serif;
}

.memb {
  margin-top: 10vh;
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.memb-heading {
  font-size: xx-large;
  text-align: center;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400;
}

.memb-heading span {
  color: #0099ff;
}

.guidelines {
  margin-top: 20px;
  text-align: left;
  font-family: "Noto Sans JP", sans-serif;
  text-align: justify;
  width: 100%;
  max-width: 800px;
}

.guidelines h1 {
  font-size: xx-large;
  text-align: center;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 300;
}

.guidelines h2 {
  font-size: 100%;
  text-align: center;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 300;
}

.guidelines h3 {
  font-size: x-large;
  font-family: "Noto Sans JP", sans-serif;
}

.guidelines p, .guidelines ul {
  font-size: 16px;
  line-height: 1.5;
}
.guidelines p span{
  color: rgb(0, 162, 255);
  font-size: large;
  font-family: "Noto Sans JP", sans-serif;
}
.guidelines .button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.guidelines .button {
  padding: 10px 20px;
  margin: 10px;
  color: white;
  background-color: #0099ff;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.guidelines .button:hover {
  background-color: #007acc;
}

@media (max-width: 1200px) {
  .member {
    padding: 0 200px;
  }

  .memb-heading {
    font-size: x-large;
  }

  .guidelines p, .guidelines ul {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .member {
    padding: 0 100px;
  }

  .memb-heading {
    font-size: large;
  }

  .guidelines p, .guidelines ul {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .member {
    padding: 0 50px;
  }

  .memb-heading {
    font-size: medium;
  }

  .guidelines p, .guidelines ul {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .member {
    padding: 0 20px;
  }

  .memb-heading {
    font-size: small;
  }

  .guidelines p, .guidelines ul {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  .member {
    padding: 0 10px;
  }

  .memb-heading {
    font-size: smaller;
  }

  .guidelines p, .guidelines ul {
    font-size: 11px;
  }
}

.Blog .Blog-container .Blog-post .Post-content .post-content-html img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.Blog .Blog-container .Blog-post .Post-content .post-content-html {
  overflow: hidden;
}


@media (max-width: 768px) {
  .cardmem {
    width: 100%;
    max-width: 400px;
  }

  .image {
    height: auto;
  }
}

@media (max-width: 576px) {
  .cardmem {
    width: 100%;
    max-width: 300px;
  }

  .image {
    height: auto;
  }
}


.Copyrights {
  text-align: center;
  margin-top: auto; 
  padding: 20px;
  background: linear-gradient(45deg, #F5F7F6, #5CA0F2);
  background-size: 300% 300%;
  animation: color 12s ease-in-out infinite;
  width: 100%;
}

.Copyrights h2 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.Copyrights p {
  font-size: 14px;
  color: #666;
}

@media (max-width: 600px) {
  
  .Copyrights h2 {
    font-size: 16px;
  }

  .Copyrights p {
    font-size: 12px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .Copyrights h2 {
    font-size: 18px;
  }

  .Copyrights p {
    font-size: 14px;
  }
}

@media (min-width: 1025px) {
  .Copyrights h2 {
    font-size: 20px;
  }

  .Copyrights p {
    font-size: 16px;
  }
}
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 162, 255, 0.5);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  z-index: 1000;
  border: none;
}

.back-to-top:hover {
  opacity: 1;
}

.back-to-top i {
  font-size: 24px;
}

@media (max-width: 768px) {
  .back-to-top {
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
  }

  .back-to-top i {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .back-to-top {
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  }

  .back-to-top i {
    font-size: 16px;
  }
}
.back-to-top1 {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 162, 255, 0.5);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s;
  z-index: 1000;
  border: none;
}

.back-to-top1:hover {
  opacity: 1;
}

.back-to-top1 i {
  font-size: 24px;
}

@media (max-width: 768px) {
  .back-to-top1 {
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
  }

  .back-to-top1 i {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .back-to-top1 {
    bottom: 10px;
    right: 35px;
    width: 30px;
    height: 30px;
  }

  .back-to-top1 i {
    font-size: 16px;
  }
}
.wrapper-but {
  display: flex;
  justify-content: center;
  margin-top: 50px; 
}

.cta {
  display: flex;
  padding: 10px 45px;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 20px; 
  color: white;
  background: #6225E6;
  transition: 1s;
  box-shadow: 6px 6px 0 black;
  transform: skewX(-15deg);
}

.cta:focus {
  outline: none;
}

.cta:hover {
  transition: 0.5s;
  box-shadow: 10px 10px 0 #FBC638;
}

.cta span:nth-child(2) {
  transition: 0.5s;
  margin-right: 0px;
}

.cta:hover span:nth-child(2) {
  transition: 0.5s;
  margin-right: 45px;
}



span:nth-child(2) {
  /* width: 20px; */
  
  position: relative;
  
}


path.one {
  transition: 0.4s;
  transform: translateX(-60%);
}

path.two {
  transition: 0.5s;
  transform: translateX(-30%);
}

.cta:hover path.three {
  animation: color_anim 1s infinite 0.2s;
}

.cta:hover path.one {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.6s;
}

.cta:hover path.two {
  transform: translateX(0%);
  animation: color_anim 1s infinite 0.4s;
}


@keyframes color_anim {
  0% {
    fill: white;
  }
  50% {
    fill: #FBC638;
  }
  100% {
    fill: white;
  }
}
@media (max-width: 1200px) {
  .cta {
    font-size: 35px;
    padding: 8px 40px;
  }

  .cta:hover span:nth-child(2) {
    margin-right: 35px;
  }
}

@media (max-width: 992px) {
  .cta {
    font-size: 30px;
    padding: 6px 35px;
  }

  .cta:hover span:nth-child(2) {
    margin-right: 30px;
  }
}

@media (max-width: 768px) {
  .cta {
    font-size: 25px;
    padding: 5px 30px;
  }

  .cta:hover span:nth-child(2) {
    margin-right: 25px;
  }
}

@media (max-width: 576px) {
  .cta {
    font-size: 20px;
    padding: 4px 25px;
  }

  .cta:hover span:nth-child(2) {
    margin-right: 20px;
  }
}

@media (max-width: 400px) {
  .cta {
    font-size: 18px;
    padding: 3px 20px;
  }

  .cta:hover span:nth-child(2) {
    margin-right: 15px;
  }
}

.responsive-image {
  width: 100%;
  height: auto;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup-content h2 {
  margin-bottom: 20px;
}

.popup-content button {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}
@media (max-width: 600px) {
  .popup-content {
    max-width: 90%; /* Reduce max width to fit small screens */
    padding: 10px; /* Reduce padding for small screens */
  }

  .popup-content button {
    padding: 8px 16px; /* Adjust button padding */
  }
}

/* Media query for medium screens (tablets) */
@media (min-width: 601px) and (max-width: 1024px) {
  .popup-content {
    max-width: 80%; /* Reduce max width to fit medium screens */
    padding: 15px; /* Adjust padding for medium screens */
  }

  .popup-content button {
    padding: 10px 18px; /* Adjust button padding */
  }
}

/* Media query for large screens (desktops) */
@media (min-width: 1025px) {
  .popup-content {
    max-width: 500px; /* Maintain maximum width for larger screens */
    padding: 20px; /* Default padding for larger screens */
  }

  .popup-content button {
    padding: 10px 20px; /* Default button padding for larger screens */
  }
}
.Time{
  /* height: 100%;
  overflow: hidden;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

   */
}
#Time-title{
  position: absolute;
  top: 70%;
  left: 0;
  right: 0;
  color: white;
  text-align: center;
  font-family: Lato;
  font-weight: 300;
  font-size: 30px;
  letter-spacing: 10px;
  
  padding-left: 10px;
}



#Time-title span{
  /* background: -webkit-linear-gradient(white, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  /* background-color: white; */
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  transform-origin: bottom;

}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  
  background-color: #006eff;
  transform-origin: top;

}


.recruitment-figure {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 6.250em;
  height: 6.250em;
  animation: rotate 2.4s linear infinite;
}

.recruitment-figure .white {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  animation: flash 2.4s linear infinite;
  opacity: 0;
}

.recruitment-figure .dot {
  position: absolute;
  margin: auto;
  width: 2.4em;
  height: 2.4em;
  border-radius: 100%;
  transition: all 1s ease;
}

.recruitment-figure .dot:nth-child(2) {
  top: 0;
  bottom: 0;
  left: 0;
  background: #FF4444;
  animation: dotsY 2.4s linear infinite;
}

.recruitment-figure .dot:nth-child(3) {
  left: 0;
  right: 0;
  top: 0;
  background: #FFBB33;
  animation: dotsX 2.4s linear infinite;
}

.recruitment-figure .dot:nth-child(4) {
  top: 0;
  bottom: 0;
  right: 0;
  background: #99CC00;
  animation: dotsY 2.4s linear infinite;
}

.recruitment-figure .dot:nth-child(5) {
  left: 0;
  right: 0;
  bottom: 0;
  background: #33B5E5;
  animation: dotsX 2.4s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  10% {
    width: 6.250em;
    height: 6.250em;
  }
  66% {
    width: 2.4em;
    height: 2.4em;
  }
  100% {
    transform: rotate(360deg);
    width: 6.250em;
    height: 6.250em;
  }
}

@keyframes dotsY {
  66% {
    opacity: .1;
    width: 2.4em;
  }
  77% {
    opacity: 1;
    width: 0;
  }
}

@keyframes dotsX {
  66% {
    opacity: .1;
    height: 2.4em;
  }
  77% {
    opacity: 1;
    height: 0;
  }
}

@keyframes flash {
  33% {
    opacity: 0;
    border-radius: 0%;
  }
  55% {
    opacity: .6;
    border-radius: 100%;
  }
  66% {
    opacity: 0;
  }
}


::-webkit-scrollbar{
  width: 6px;
}


::-webkit-scrollbar-track{
  background-color: black;
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: #00c3ff;
}

::-webkit-scrollbar-thumb:hover{
  background-color: violet;
 
}
/* Add this to your CSS file */

.button12 {
  display: inline-flex;
  position: relative;
  height: 60px;
  margin-right: 20px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  background: #fff;
  color: #705df2;
  border-radius: 36px;
  box-shadow: 0px 24px 74px rgb(60 0 189 / 20%);
  font-size: 16px;
  font-weight: 700;
  padding: 0 30px;
  text-decoration: unset;
}
.sect{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above other content */
}

/* Modal content styling */
.modal-content {
  position: relative; /* To position the close button */
  width: 90vw; /* 90% of viewport width */
  height: 80vh; /* 80% of viewport height */
  max-width: 700px; /* Maximum width */
  max-height: 600px; /* Maximum height */
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(180deg, #000000,  #171242, rgb(11, 27, 58), rgb(0, 0, 0));
  overflow: auto; /* Enable scrolling if content exceeds modal size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column; /* To align items vertically */
  box-sizing: border-box;
  align-items: center;
  color: white;
  
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f44336; /* Red background */
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-button:hover {
  background-color: #d32f2f;
}

/* Ensure images inside modal are responsive */
.modal-content img {
  max-width: 100%;
  max-height: 70vh; /* Adjust to fit within modal */
  object-fit: contain; /* Ensure image scales to fit without distortion */
  margin: auto;
}



/* Calendar Container */
.calendar-container {
  margin-top: 20px;
  padding: 20px;
  height: auto;
  max-height: 80vh;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calendar-buttons {
  margin-bottom: 20px;
}

.calendar-buttons button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.calendar-buttons button:hover {
  background-color: #0056b3;
}

.react-calendar {
  border: 1px solid #ddd;
  border-radius: 8px;
  background: linear-gradient(45deg, #F5F7F6, #5CA0F2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  font-size: x-large;
  font-weight: bold;
  width: 100%;
  max-width: 800px;
  height: auto;
  line-height: 50px;
}

@media (max-width: 768px) {
  .react-calendar {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .react-calendar {
    font-size: 12px;
  }

  .calendar-buttons button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* Additional styles for calendar tiles and highlighted tiles */
.react-calendar__tile {
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  
}
.react-calendar__navigation__label{
  font-size: x-large;
  font-weight: bold;
}

.react-calendar__tile--active {
  background-color: #ffffff;
  color: #fff;
}

.react-calendar__tile--now {
  border: 2px solid #007bff;
  border-radius: 10%;
  background-color: #e3f2fd;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.react-calendar__month-view__days__day {
  color: #333;
  position: relative;
}

/* Base styling for event name */
.event-name {
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  font-size: 10px; /* Base font size */
  font-weight: bolder;
  color: #00ff55;
  text-align: left;
  margin-top: 0px;
  line-height: 15px;

}

/* Responsive styling for larger screens */
@media (min-width: 768px) {
  .event-name {
    font-size: 10px; /* Slightly larger font size */
    line-height: 18px; /* Adjust line height */
  }
}

@media (min-width: 1024px) {
  .event-name {
    font-size: 12px; /* Larger font size for bigger screens */
    line-height: 20px; /* Adjust line height */
  }
}

/* Responsive styling for very small screens */
@media (max-width: 480px) {
  .event-name {
    font-size: 6px; /* Smaller font size for very small screens */
    line-height: 12px; /* Adjust line height */
    bottom: 4px; /* Adjust bottom positioning */
    left: 4px; /* Adjust left positioning */
    right: 4px; /* Adjust right positioning */
  }
}

.event-dot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #00FF00; /* Default green color */
  box-shadow: 0 0 10px #00FF00; /* Default green glow effect */
  animation: blink 1s infinite; /* Default blinking */
}

/* Dot styling for green core events */
.event-dot.green-core {
  background-color: #00FF00; /* Green color */
  box-shadow: 0 0 10px #00FF00; /* Green glow effect */
}

/* Dot styling for yellow non-core events */
.event-dot.yellow-non-core {
  background-color: #FFFF00; /* Yellow color */
  box-shadow: 0 0 10px #FFFF00; /* Yellow glow effect */
}

/* Dot styling for red future events */
.event-dot.red-future-event {
  background-color: #FF0000; /* Red color */
  box-shadow: 0 0 10px #FF0000; /* Red glow effect */
}
.event-tooltip {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.event-tooltip div {
  cursor: pointer;
  margin: 5px 0;
}
/* Animation for blinking */
@keyframes blink {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.2); /* Slightly enlarges during blink */
  }
}

.modal-content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* Register Now button styling */
.register-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4CAF50; /* Green background */
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-button:hover {
  background-color: #0077ff; /* Darker green on hover */
}
.qr-code-container {
  text-align: center;
  margin-top: 20px;
}

.qr-code-image {
  max-width: 100%;
  height: auto;
}
.highlight-red {
  
  color: rgb(255, 0, 0) !important;
}


.events-containerz {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.eventz {
  flex: 1;
  min-width: 300px;
  margin: 10px;
  text-align: center;
}
.eventz h1 {
  color: white;
  font-family: "Playfair Display", serif;
  font-size: 24px; /* Default font size */
  margin: 0; /* Optional: reset margin for consistency */
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .eventz h1 {
    font-size: 32px; /* Larger font size for tablets */
  }
}

@media (min-width: 1024px) {
  .eventz h1 {
    font-size: 40px; /* Larger font size for desktops */
  }
}

@media (min-width: 1440px) {
  .eventz h1 {
    font-size: 48px; /* Even larger font size for large screens */
  }
}
.cta-button {
  display: inline-block;
  padding: 12px 24px;
  margin-top: 20px;
  background-color: #007BFF; /* Button background color */
  color: white; /* Button text color */
  font-size: 16px;
  font-family: "Playfair Display", serif; /* Matching the font of the title */
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds a subtle shadow */
}

.cta-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
/* Countdown Timer Styling */
.countdown-container {
  color: #ffffff;
  margin: 20px auto;
  text-align: center;
 
}

.countdown-container h1 {
  font-weight: normal;
  letter-spacing: .125rem;
  text-transform: uppercase;
}

.countdown-container li {
  display: inline-block;
  font-size: 1.5em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
  line-height: normal;
}

.countdown-container li span {
  display: block;
  font-size: 4.5rem;
}

.emoji {
  display: none;
  padding: 1rem;
}

.emoji span {
  font-size: 4rem;
  padding: 0 .5rem;
}

@media all and (max-width: 768px) {
  .countdown-container h1 {
    font-size: calc(1.5rem * var(--smaller));
  }

  .countdown-container li {
    font-size: calc(1.125rem * var(--smaller));
  }

  .countdown-container li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}


#Time-title1 span {
  display: block;
  font-size: 30px;
  font-weight: bold;
  color: white;
}

.event-image {
  width: 100%;
  height: auto;
  margin: 10px 0;
}

.button12 {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.button12 .label {
  font-weight: bold;
}

@media (max-width: 768px) {
  .events-container {
    flex-direction: column;
  }

  .event {
    margin: 10px 0;
  }
}

/* Media Queries */
@media (max-width: 1200px) {
  #Time-title1 span {
    font-size: 25px;
  }
}

@media (max-width: 992px) {
  #Time-title1 span {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .events-container {
    flex-direction: column;
  }

  .event {
    margin: 10px 0;
  }

  #Time-title1 span {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  #Time-title1 span {
    font-size: 16px;
  }
}

/* src/components/Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.modal h2 {
  margin-top: 0;
}

.close-button {
  background: #ef4444;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button:hover {
  background: #d32f2f;
}

/* Styling for the poll question and votes */
.votes {
  margin-bottom: 15px;
}

.votes input[type="radio"] {
  display: none;
}

.votes label {
  background: white;
  border: 4px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: border-color 0.3s;
}

.votes input[type="radio"]:checked + label {
  border-color: var(--main-color);
}

.votes .text-2xl {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.votes progress {
  width: 100%;
  height: 10px;
  border-radius: 5px;
}

.votes progress::-webkit-progress-bar {
  background: #cbd5e1;
  border-radius: 5px;
}

.votes progress::-webkit-progress-value {
  background: var(--main-color);
  border-radius: 5px;
}

.votes small {
  color: #6b7280;
}
.cardgen{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.digi-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.digi-image {
  max-width: 100%;
  height: auto;
  width: 300px; /* You can adjust the width to fit your design */
  border-radius: 10px; /* Optional: to add rounded corners */
}
.digi-containers {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.digi-images {
  width: 80%; /* Adjust the width to make sure the image is large but not too large */
  max-width: 600px; /* Optional: max-width to ensure it doesn't get too big */
  height: auto;
  border-radius: 10px; /* Optional: to add rounded corners */
  object-fit: contain; /* Ensures the entire image is displayed */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .digi-images {
    width: 90%; /* Slightly smaller width on smaller screens */
    max-width: 400px; /* Optional: adjust max-width for smaller screens */
  }
}

@media (max-width: 480px) {
  .digi-images {
    width: 100%; /* Full width for very small screens */
    max-width: 300px; /* Optional: further adjust max-width for very small screens */
  }
}

.space {
  background: url('./images/earth-1756274.jpg') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.space::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the transparency with the alpha value */
  z-index: -1;
}
.azure {
  background: url('./images/wp329014-microsoft-windows-desktop-background.png') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.azure::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the transparency with the alpha value */
  z-index: -1;
}
.inau {
  background: url('./images/con.jpg') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.inau::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the transparency with the alpha value */
  z-index: -1;
}
.gen {
  background: url('./images/genbg.webp') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.gen::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the transparency with the alpha value */
  z-index: -1;
}
.digitalart {
  background: url('./images/wallpaperflare.com_wallpaper\ \(1\).jpg') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.digitalart 
  ::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: -1;
}
.synergy {
  background: url('./images/frebg.jpg') no-repeat center center fixed;
  background-size: cover;
  position: relative;
}

.synergy 
  ::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  z-index: -1;
}
@media (max-width: 767px) {
  .azure {
    background: none; /* Removes the background image */
  }

  .azure::before {
    background: none; /* Removes the transparent overlay */
  }
  .space {
    background: none; /* Removes the background image */
  }

  .space::before {
    background: none; /* Removes the transparent overlay */
  }
  .azure {
    background: none; /* Removes the background image */
  }

  .azure::before {
    background: none; /* Removes the transparent overlay */
  }
  .inau {
    background: none; /* Removes the background image */
  }

  .inau::before {
    background: none; /* Removes the transparent overlay */
  }
  .gen {
    background: none; /* Removes the background image */
  }

  .gen::before {
    background: none; /* Removes the transparent overlay */
  }
  .digitalart {
    background: none; /* Removes the background image */
  }

  .digitalart::before {
    background: none; /* Removes the transparent overlay */
  }
  .synergy {
    background: none; /* Removes the background image */
  }

  .synergy::before {
    background: none; /* Removes the transparent overlay */
  }
}
.poster-image-container {
  padding: 20px; /* Add padding around the image */
  text-align: center; /* Center the image within the container */
}

.poster-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Optional: adds rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: adds a subtle shadow */
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .poster-image {
    max-width: 60%; /* Smaller for tablets */
  }
}

@media (min-width: 1024px) {
  .poster-image {
    max-width: 40%; /* Smaller on larger screens */
  }
}

@media (min-width: 1440px) {
  .poster-image {
    max-width: 30%; /* Even smaller on very large screens */
  }
}

/* Dropdown Container */
.dropdown-container {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

select {
  padding: 10px 15px;
  border: 2px solid lightblue;
  border-radius: 5px;
  background-color: transparent;
  font-size: 16px;
  color: #ffffff;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  outline: none;
}

select:hover {
  border-color: #0099ff;
}

select:focus {
  border-color: #0066cc;
  box-shadow: 0 0 8px rgba(0, 102, 204, 0.4);
}

select option {
  padding: 12px;
  font-size: 16px;
  color: #333;
  background-color: #ffffff;
}

.dropdown-container::after {
  content: '\25BC'; /* Unicode for downward arrow */
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  color: #ffffff;
  pointer-events: none;
}

/* Responsive dropdown for smaller screens */
@media (max-width: 768px) {
  select {
    font-size: 14px;
    padding: 8px 12px;
  }
}


.cards1 .Navya,.likil-class, .aafiya-class, .hariharan-class, .bargav-class, .dhruva-class, .sanjesh-class, .saikrishna-class, .geosovi-class, .mokshini-class,.subin-class, .dharsan-class, .vishal-class, .ukenthiran-class, .lokesh-class, .shahul-class, .trimurthulu-class, .joyclyn, 
.hadlins-price, 
.harismitha, 
.hema-s, 
.jangala-sri-vaishnavi, 
.katepalli-pavani-priya, 
.nirbhay, 
.shreyas-s
{
  width: 100%;
  height: auto;
  max-width: 200px;
}
.Navya img{
  width: 135%;
  height: auto;
}
.likil-class img{
  width: 135%;
  height: auto;
}
.aafiya-class img{
  width: 145%;
  height: auto;

}
.hariharan-class img{
  width: 145%;
  height: auto;
}
.bargav-class img{
  width: 125%;
  height: auto;
}
.dhruva-class img{
  width: 170%;
  height: auto;
}
.sanjesh-class img{
  width: 195%;
  height: auto;
}
.saikrishna-class img{
  width: 175%;
  height: auto;
}
.geosovi-class img{
  width: 155%;
  height: auto;
}
.mokshini-class img{
  width: 165%;
  height: auto;
}
.subin-class img{
  width: 155%;
  height: auto;

}
.dharsan-class img{
  width: 180%;
  height: auto;
}
.vishal-class img{
  width: 155%;
  height: auto;

}
.ukenthiran-class img{
  width: 155%;
  height: auto;
}
.lokesh-class img{
  width: 175%;
  height: auto;
}
.shahul-class img{
  width: 155%;
  height: auto;
}
.trimurthulu-class img{
  width: 155%;
  height: auto;
}
.joyclyn img{
  width: 129%;
  height: auto;
}
.hadlins-price img{
  width: 136%;
  height: auto;
}
.harismitha img{
  width: 135%;
  height: auto;

}
.hema-s img{
  width: 135%;
  height: auto;
}
.jangala-sri-vaishnavi img{
  width: 135%;
  height: auto;
}
.katepalli-pavani-priya img{
  width: 170%;
  height: auto;
}
.nirbhay img{
  width: 135%;
  height: auto;
}
.shreyas-s img{
  width: 335%;
  height: auto;
}
.Unit{
  background: #000000;
}
.chapter-title {
  font-size: 22px;
  font-weight: bold;
  color: red;
}

.presents {
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
}

.challenge-title {
  font-size: 40px;
  font-weight: bold;
  color: red;
  margin-top: 10px;
}

.challenge-subtitle {
  display: block;
}

.event-details {
  background-color: #333;
  padding: 15px;
  border-radius: 8px;
  margin: 20px auto;
  width: 80%;
}

.event-details p {
  font-size: 18px;
  margin: 8px 0;
}

.register-btn {
  background-color: red;
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 25px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
}

.register-btn:hover {
  background-color: darkred;
}

/* HiringSection.css */
.hiring-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, #1e3c72, #2a5298);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 850px;
  margin: auto;
  color: white;
}

.hiring-container h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #ff9a9e, #fad0c4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.hiring-posters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 25px;
}

.poster {
  width: 260px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out;
}

.poster:hover {
  transform: scale(1.05);
}

.hiring-container p {
  font-size: 1.2rem;
  font-weight: 500;
  max-width: 650px;
  margin-bottom: 20px;
  color: #f8f9fa;
}

.registerhir-btn {
  background: linear-gradient(135deg, #ff7eb3, #ff758c);
  color: white;
  padding: 12px 28px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 12px rgba(255, 117, 140, 0.4);
  position: relative;
  overflow: hidden;
}

.registerhir-btn:hover {
  background: linear-gradient(135deg, #ff5470, #ff758c);
  box-shadow: 0 6px 18px rgba(255, 117, 140, 0.6);
  transform: translateY(-3px);
}
@media (max-width: 768px) {
  .hiring-container {
    padding: 20px;
    max-width: 90%;
  }

  .hiring-container h2 {
    font-size: 2rem;
  }

  .hiring-posters {
    flex-direction: column;
    gap: 15px;
  }

  .poster {
    width: 90%;
    max-width: 300px;
  }

  .hiring-container p {
    font-size: 1rem;
    max-width: 90%;
  }

  .registerhir-btn {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .hiring-container {
    padding: 15px;
    max-width: 95%;
  }

  .hiring-container h2 {
    font-size: 1.8rem;
  }

  .poster {
    width: 100%;
    max-width: 250px;
  }

  .hiring-container p {
    font-size: 0.9rem;
  }

  .registerhir-btn {
    font-size: 0.9rem;
    padding: 8px 18px;
  }
}
/* Container styling */
.event-cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  padding: 40px;
  /* background: #0f172a; */
}

/* Individual event card */
.event-card {
  width: 320px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  padding: 20px;
  text-align: center;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Hover effect */
.event-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 25px rgba(255, 255, 255, 0.2);
}

/* Title Styling */
.chapter-title {
  font-size: 16px;
  color: #00bcd4;
  font-weight: bold;
  text-transform: uppercase;
}

.presents {
  font-size: 14px;
  color: #f9a825;
  font-weight: bold;
  margin-top: 5px;
}

.challenge-title {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
}

.challenge-subtitle {
  color: #f57c00;
  font-weight: bold;
}

/* Event Details */
.event-details {
  margin-top: 15px;
  font-size: 14px;
  color: #ddd;
}

.event-details p {
  margin: 5px 0;
}

/* Register Button */
.register-btn {
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(45deg, #ff4081, #ff9100);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 10px rgba(255, 105, 135, 0.5);
}

.register-btn:hover {
  background: linear-gradient(45deg, #ff9100, #ff4081);
  box-shadow: 0px 6px 15px rgba(255, 105, 135, 0.8);
}
@media screen and (max-width: 1024px) {
  .event-cards-container {
    gap: 20px;
    padding: 30px;
  }
  .event-card {
    width: 90%;
    max-width: 400px;
  }
}

/* Mobile devices (480px - 767px) */
@media screen and (max-width: 767px) {
  .event-cards-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }
  .event-card {
    width: 95%;
    max-width: 350px;
  }
}

/* Small mobile screens (up to 480px) */
@media screen and (max-width: 480px) {
  .event-cards-container {
    padding: 15px;
    gap: 15px;
  }
  .event-card {
    width: 100%;
    max-width: 320px;
  }
  .register-btn {
    font-size: 12px;
    padding: 8px 16px;
  }
}